import {
  CloseModalEvent,
  completeConnection,
  envs,
  establishConnection,
  Event,
  eventFactory,
  OpenModalCompleteEvent,
  OpenModalEvent,
  OpenPopupBlockedEvent,
  OpenPopupCompleteEvent,
  OpenPopupEvent,
  SentryError,
  startListening,
  UnrecoverableErrorEvent,
  urls,
} from '@sentry/shared';
import { ENVIRONMENTS } from '@sentry/shared/dist/environments';
import { css, html, LitElement } from 'lit';
import { customElement, property, queryAsync } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

import { popupCenter } from './helpers';
import { build, buildTime, version } from './version';

function getWrapperEnv(forceStage = false) {
  return forceStage ? () => ENVIRONMENTS.stage : envs.getWrapperEnv;
}

function getAppUrl(forceStage = false) {
  const { getAppUrl } = urls.create(getWrapperEnv(forceStage));
  return getAppUrl();
}

const { sentryUrl } = urls;

declare global {
  interface Window {
    __sentry__: any;
  }
}

window.__sentry__ = {
  info: {
    version,
    build,
    buildTime,
  },
};

@customElement('susi-sentry')
class Sentry extends LitElement {
  static styles = css`
    s :host {
      display: block;
    }

    :host,
    section,
    iframe {
      width: 100%;
      height: 100%;
      background: transparent;
      color-scheme: normal;
      border: 0;
    }

    #sentry-container.modal-mode {
      display: none;
    }

    #sentry-container.modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal #sentry {
      box-sizing: border-box;
      padding: 40px;
      border-radius: 5px;

      background-color: #fff;
    }

    .modal.modal--dark-mode #sentry {
      background-color: #262626;
    }

    .modal--transitionable {
      transition: all 0.5s ease-in-out;
    }
  `;

  private container?: HTMLElement | null = null;
  private root?: HTMLElement | null = null;
  private _port: MessagePort | null = null;
  private events: any;

  @queryAsync('#iframe')
  _iframe!: Promise<HTMLIFrameElement>;

  @property()
  variant = 'large-buttons';

  @property({ type: Boolean })
  popup = false;

  @property({ type: Boolean })
  stage = false;
  modalOpen = false;

  @property({ type: Boolean })
  modalTransitionable = false;

  @property({ type: Number })
  addModalTransitionableClassTimeout: number | null = null;

  @property({
    type: Object,
  })
  authParams: Record<string, string> = {};

  @property({
    type: Object,
  })
  config: Record<string, string> = {
    version,
    build,
    buildTime,
  };

  async connectedCallback() {
    super.connectedCallback();

    const iframe = await this._iframe;
    iframe?.addEventListener('load', () =>
      setTimeout(() => {
        if (!this._port) {
          this.dispatchError();
        }
      }, 2000)
    );
    iframe?.addEventListener('error', () => this.dispatchError());

    if (this.authParams && this.authParams.client_id) {
      this.connectToApp();
    }
  }

  updated(changedProperties: any) {
    if (
      changedProperties.has('authParams') ||
      changedProperties.has('config')
    ) {
      this.connectToApp();
    }
  }

  connectToApp() {
    window.addEventListener('message', this.onLastResort, { once: true });

    const environment = getAppUrl(this.stage);

    establishConnection(environment)
      .then(port => completeConnection(port, this.appState))
      .then(port => {
        this._port = port;
        this.events = {
          popupOpenComplete: eventFactory(port)(OpenPopupCompleteEvent),
          popupOpenBlocked: eventFactory(port)(OpenPopupBlockedEvent),
          modalOpenComplete: eventFactory(port)(OpenModalCompleteEvent),
        };

        if (this.shadowRoot) {
          this.container = this.shadowRoot.getElementById('sentry-container');
          this.root = this.shadowRoot.getElementById('sentry');
        }

        return port;
      })
      .then(port => {
        const eventListener = startListening(port)();
        eventListener.subscribe(event => this.onReceiveSentryEvent(event));
      })
      .catch(e => this.dispatchError(e.data));
  }

  onLastResort = (e: MessageEvent) => {
    const appUrl = getAppUrl(this.stage);
    if (e.origin === appUrl) {
      try {
        const event = Event.fromString(e.data);
        if (event && event.of(UnrecoverableErrorEvent)) {
          this.dispatchError(event.data);
        }
      } catch (e) {
        this.dispatchError();
      }
    }
  };

  onReceiveSentryEvent(event: Event) {
    if (event.side === 'proxy') {
      this.dispatchEvent(new CustomEvent(event.name, { detail: event.data }));
    }

    if (event.side === 'app' && event.of(OpenPopupEvent)) {
      return this.openPopup(event.data);
    }

    if (event.side === 'app' && event.of(OpenModalEvent)) {
      const { width, height } = event.data;
      const firstOpen = !this.modalOpen;
      this.activateModal();

      if (this.root) {
        this.root.style.width = width;
        this.root.style.height = height;

        setTimeout(
          () => {
            this.events.modalOpenComplete();
          },
          firstOpen ? 0 : 500
        );
      }
    }

    if (event.side === 'app' && event.of(CloseModalEvent)) {
      const { width, height } = event.data;
      this.modalOpen = false;

      if (this.root) {
        this.root.style.width = width;
        this.root.style.height = height;
        this.root.classList.remove('modal--transitionable');
        if (this.addModalTransitionableClassTimeout) {
          clearTimeout(this.addModalTransitionableClassTimeout);
          this.addModalTransitionableClassTimeout = null;
        }
      }
    }
  }

  openPopup(url: string) {
    const popupWindow = popupCenter({
      url,
      title: 'SUSI Light Login',
      w: 459,
      h: 768,
    });

    if (popupWindow) {
      this.events?.popupOpenComplete();
    } else {
      this.events?.popupOpenBlocked();
    }
  }

  activateModal() {
    if (!this.modalOpen && this.root) {
      this.modalOpen = true;
      this.requestUpdate();

      const timeout = setTimeout(() => {
        this.root?.classList.add('modal--transitionable');
      }, 1000);

      this.addModalTransitionableClassTimeout = timeout;
    }
  }

  dispatchError(
    detail: SentryError | { name: 'unrecoverable' } = { name: 'unrecoverable' }
  ) {
    this.dispatchEvent(new CustomEvent('on-error', { detail }));
  }

  @property()
  get appConfig() {
    return {
      ...this.config,
      popup: this.popup,
      variant: this.variant,
    };
  }

  get authState(): Record<string, string> {
    return {
      ...this.authParams,
      wrapper: 'true',
      popup: this.popup.toString(),
      asserted_origin: window.origin,
      sl_version: version,
      sl_build: build,
      sl_buildtime: buildTime,
    };
  }

  get appState() {
    return {
      authParams: this.authState,
      config: this.appConfig,
    };
  }

  get url() {
    const baseUrl = sentryUrl(getWrapperEnv(this.stage));
    const url = baseUrl(this.variant);
    for (const property in this.appState.authParams) {
      url.searchParams.append(property, this.appState.authParams[property]);
    }

    return url.href;
  }

  get isDarkMode() {
    return this.config.modal && !!this.authParams.dt;
  }

  render() {
    return html`<section
      id="sentry-container"
      class=${classMap({
        'modal-mode': this.config.modal,
        'modal--dark-mode': this.isDarkMode,
        modal: this.modalOpen,
      })}
    >
      <section id="sentry">
        <iframe
          allowtransparency="true"
          frameborder="0"
          id="iframe"
          src="${this.url}"
        ></iframe>
      </section>
    </section>`;
  }
}

export default Sentry;
