import '@sentry/wrapper';

import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';

declare global {
  interface Window {
    adobeid: any;
    adobeIMS: any;
    SENTRY_EVENTS: any[];
  }
}

function preRedirectWork() {
  return new Promise(resolve => {
    const tick = () => {
      this.progress += 1;

      setTimeout(() => {
        if (this.progress < 3) {
          return tick();
        }
        this.progress = 0;
        resolve(null);
      }, 1000);
    };

    tick();
  });
}

const searchParams = new URLSearchParams(window.location.search);
const authParams = [...searchParams.entries()].filter(
  ([key]) => key !== 'config'
);
const config = JSON.parse(searchParams.get('config') ?? '{}');
const isPopup = !!config.popup;

@customElement('sample-app')
export class SampleApp extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }

    :host section {
      display: grid;
      grid-template-rows: 65px auto;
      background: var(--spectrum-gray-75) !important;
    }

    :host main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
    }

    :host,
    section {
      width: 100%;
      height: 100%;
    }

    progress {
      visibility: hidden;
      width: 334px;
      margin-bottom: 20px;
    }

    progress.show {
      visibility: visible;
    }

    .language-picker {
      background-color: var(--spectrum-gray-75);
      border: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
      margin-left: 10px;
      padding: 5px;
    }

    .theme-button {
      background-color: var(--spectrum-gray-75);
      border: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
      margin-left: 10px;
      padding: 5px;
    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--spectrum-gray-75);
      border-radius: 4px;
      border: solid 1px var(--spectrum-gray-200);
    }

    .card-express-theme {
      width: 360px;
      height: 500px;
      padding: 32px;
    }

    .card-large-buttons {
      width: 334px;
      height: 482px;
      padding: 26px;
    }

    .card-complete-account {
      width: 510px;
      height: 700px;
      padding: 56px;
    }

    .card iframe {
      width: 100%;
      height: 100%;
    }

    .code {
      width: 600px;
      font-size: 18px;
      word-break: break-word;
    }

    .adobe-logo {
      width: 85px;
      display: flex;
      justify-content: space-between;
    }

    .adobe-logo__text {
      font-weight: 700;
      font-size: 18px;
      color: #fa0f00;
    }

    .global-nav {
      display: flex;
      justify-content: center;
      background: var(--spectrum-gray-75);
      border-bottom: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
    }

    .global-nav__navigation {
      max-width: 1440px;
      flex-grow: 1;
    }

    .global-nav__navigation-list {
      display: flex;
    }

    .global-nav,
    .global-nav__navigation-list,
    .global-nav__navigation-list-item {
      height: 100%;
    }

    .global-nav__navigation-list,
    .global-nav__navigation-list-item {
      padding: 0;
      margin: 0;
    }

    .global-nav__navigation-list-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: adobe-clean, Source Sans Pro, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin: 0 16px;
    }

    .global-nav__navigation-list-item--right {
      margin-left: auto;
    }

    sp-switch {
      color: var(--spectrum-gray-900);
      font-family: adobe-clean, Source Sans Pro, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
      font-size: 14px;
      padding: 10px;
    }

    .options-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .redirect-link {
      font-family: adobe-clean, Source Sans Pro, -apple-system;
      text-decoration: none;
    }

    section {
      height: 100%;
    }

    sp-theme {
      height: 100%;
    }
  `;

  @property()
  progress = 0;

  @property()
  isPopup = isPopup;

  @property({ type: Object })
  userData: { displayName: string; email: string } | null = null;

  @property()
  token = '';

  @property({ type: Object })
  authState = {
    client_id: 'sentry-test',
    scope: 'AdobeID,openid',
    locale: 'en-us',
    response_type: 'token',
    dt: window?.matchMedia('(prefers-color-scheme: dark)')?.matches,
    ...authParams.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
  };

  @property({ type: Object })
  config = {
    ...config,
    consentProfile: config.consentProfile ?? 'adobe-id-sign-up',
  };

  connectedCallback() {
    super.connectedCallback();
    window.SENTRY_EVENTS = [];
    const qs = new URLSearchParams(window.location.hash.substring(1));
    if (qs.has('access_token')) {
      this._onToken({ detail: qs.get('access_token') });
    }
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    ['on-load', 'on-auth-failed'].forEach(ev => {
      const sentryElement = this.shadowRoot?.querySelector('#sentry');
      if (sentryElement) {
        sentryElement.addEventListener(ev, e => {
          window.SENTRY_EVENTS.push({
            name: e.type,
            detail: e.detail,
          });
        });
      }
    });
  }

  async _onRedirect(e) {
    const redirectUri = e.detail;
    await preRedirectWork.apply(this);
    window.location.assign(redirectUri);
  }

  _onProviderClicked(e) {
    console.log('provider clicked', e.detail);
  }

  async _onToken(e) {
    const token = e.detail;
    if (searchParams.get('disable_local_msw') === 'true') {
      this.token = token;
    }
    window.adobeIMS.setStandAloneToken({ token, expirems: 3600 });
    window.adobeIMS.getProfile().then((profile: any) => {
      this.userData = profile;
    });
  }

  async _onAuthCode(e) {
    const code = e.detail;
    if (searchParams.get('disable_local_msw') === 'true') {
      this.token = code;
    }
  }

  async _onAuthFailed(e) {
    if (e.detail.reason === 'popup-blocked') {
      const redirectUri = e.detail.fallbackUrl;
      await preRedirectWork.apply(this);
      window.location.assign(redirectUri);
    }
  }

  async _onError(e) {
    if (e.detail.name === 'critical') {
      console.error('critical', e);
    }

    if (e.detail.name === 'unrecoverable') {
      console.error('unrecoverable', e);
    }
  }

  onThemeChange() {
    this.authState = {
      ...this.authState,
      dt: !this.authState.dt,
    };
  }

  get variant() {
    if (config.variant) {
      return config.variant;
    }

    return 'large-buttons';
  }

  get authParams() {
    const extraParams: Record<string, string> = {};
    const serviceParams: Record<string, string> = {};

    extraParams['redirect_uri'] =
      'https://auth-light-sample.identity-stage.adobe.com';

    if (searchParams.get('disable_local_msw') === 'true') {
      serviceParams['disable_local_msw'] = 'true';
    }

    return { ...this.authState, ...extraParams, ...serviceParams };
  }

  localeChanged = (e: any) => {
    this.authState = {
      ...this.authParams,
      locale: e.target.value,
    };
  };

  susiLight = () => {
    return html`<susi-sentry
      id="sentry"
      .authParams=${this.authParams}
      .config=${this.config}
      .popup=${this.isPopup}
      .variant=${this.variant}
      @redirect=${this._onRedirect}
      @on-provider-clicked=${this._onProviderClicked}
      @on-token=${this._onToken}
      @on-auth-code=${this._onAuthCode}
      @on-auth-failed=${this._onAuthFailed}
      @on-error=${this._onError}
      @on-load=${e => console.log('onload', e.detail.ready)}
    ></susi-sentry>`;
  };

  susiLightElm = () => {
    return html`<susi-sentry-light
      id="sentry"
      .authParams=${this.authParams}
      .config=${this.config}
      .popup=${this.isPopup}
      .variant=${this.variant}
      @redirect=${this._onRedirect}
      @on-provider-clicked=${this._onProviderClicked}
      @on-token=${this._onToken}
      @on-auth-code=${this._onAuthCode}
      @on-auth-failed=${this._onAuthFailed}
      @on-error=${this._onError}
      @on-load=${e => console.log('onload', e.detail.ready)}
    ></susi-sentry-light>`;
  };

  render() {
    return html`
      <sp-theme theme="spectrum" color=${this.authState.dt ? 'dark' : 'light'}>
        <section>
          <header class="global-nav">
            <nav class="global-nav__navigation">
              <ul class="global-nav__navigation-list">
                <li class="global-nav__navigation-list-item">
                  <div class="adobe-logo">
                    <img
                      class="adobe-logo__icon"
                      src="https://www.adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg"
                      width="25px"
                    />
                    <span class="adobe-logo__text">Adobe</span>
                  </div>
                </li>
                <li class="global-nav__navigation-list-item">
                  <a
                    href="https://git.corp.adobe.com/pages/adobe-identity/identity-sentry/"
                    class="redirect-link"
                    >Read Docs</a
                  >
                </li>
                <li class="global-nav__navigation-list-item">
                  <a
                    href="https://auth-light-tessera.identity-stage.adobe.com/"
                    class="redirect-link"
                    >React sample</a
                  >
                </li>
                <li
                  class="global-nav__navigation-list-item global-nav__navigation-list-item--right"
                >
                  ${this.userData
                    ? html`<span
                        >Signed in as ${this.userData?.displayName}</span
                      >`
                    : html`<span>Sign in</span>`}
                  ${this.userData?.email
                    ? html`<span>(${this.userData?.email})</span>`
                    : nothing}
                </li>
              </ul>
            </nav>
          </header>
          <main>
            ${this.token
              ? html`<section id="token">${this.token}</section>`
              : nothing}
            <div class="options-container">
              <select class="language-picker" @change=${this.localeChanged}>
                <option
                  value="en-us"
                  ?selected=${'en-us' === this.authParams.locale}
                >
                  English
                </option>
                <option
                  value="es_ES"
                  ?selected=${'es_ES' === this.authParams.locale}
                >
                  Spanish
                </option>
                <option
                  value="fr-jp"
                  ?selected=${'fr-jp' === this.authParams.locale}
                >
                  French
                </option>
              </select>
              <button
                class="theme-button"
                type="button"
                @click=${this.onThemeChange}
              >
                Dark Mode: ${this.authState.dt ? 'ON ' : 'OFF'}
              </button>
            </div>
            <progress
              class=${classMap({ show: this.progress > 0 })}
              .value=${this.progress}
              max="3"
            ></progress>
            <div class=${`card card-${this.variant}`}>
              ${this.variant === 'edu-express'
                ? this.susiLightElm()
                : this.susiLight()}
            </div>
          </main>
        </section>
      </sp-theme>
    `;
  }
}
